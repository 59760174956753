import { useQuery } from '@tanstack/react-query';
import { useTitle } from 'ahooks';
import { Button, Form, Input, Switch, message } from 'antd';
import dayjs from 'dayjs';
import { get, isNil } from 'lodash-es';
import { useGlobalManagementStore } from '../apartment/myInfo/useGlobalManagement';
import { useGlobalMyInfo } from '../apartment/myInfo/useGlobalMyInfo';
import { honoClient } from './honoClient';
import { useRequestHelp } from '../test/RequestHelp';

export default function MyInfo() {
	const [form] = Form.useForm();
	const { globalManagement, setGlobalManagement } = useGlobalManagementStore();
	const diagnose = useRequestHelp();
	useTitle('我的信息');
	const [stateParams, setStateParams] = useGlobalMyInfo();
	const { data, isLoading } = useQuery({
		queryKey: ['myInfo'],
		queryFn: async () => {
			const res = await honoClient.myInfo.$get({
				json: {},
			});

			return await res.json();
		},
	});
	if (isLoading) {
		return <div>加载中</div>;
	}

	return (
		<div className="flex flex-col gap-3 container bg-white p-2">
			{isNil(data?.team) && <div>未有归属团队, 请跟管理员联系</div>}
			{[
				{
					label: '用户id',
					field: 'id',
					isKey: true,
				},
				{
					label: '角色',
					field: 'team.role',
				},
				{
					label: '名称',
					field: 'name',
					isEdit: true,
				},
				{
					label: '用户名',
					field: 'username',
					// isEdit: true,
				},
				{
					label: '用户邮箱',
					field: 'primaryEmail',
				},
				{
					label: '上次登陆于',
					field: 'lastSignInAt',
					type: 'time',
					render: (i: string) => dayjs(i).format('YYYY-MM-DD HH:mm'),
				},
			].map((i) => {
				return (
					<div key={i.field} className="flex flex-row gap-2">
						<div>{i.label}:</div>
						<div>
							{i.render ? i.render(get(data, i.field)) : get(data, i.field)}
						</div>
					</div>
				);
			})}

			<Form
				className="flex flex-col gap-2"
				style={{ maxWidth: 350 }}
				form={form}
				initialValues={{
					...stateParams,
					globalManagementPassword: undefined,
				}}
				onValuesChange={(v, all) => {
					setStateParams((pre) => {
						return {
							...pre,
							...all,
						};
					});
				}}
				onFinish={(v) => {
					if (v.globalManagementPassword !== 'wood999') {
						message.error('请重试');

						return;
					}

					setGlobalManagement(true);
					message.success('成功');
				}}
			>
				<Form.Item
					label="全局敏感信息隐藏"
					name="globalSensitiveInformationHiding"
				>
					<Switch />
				</Form.Item>

				{globalManagement ? (
					<>
						<Button
							onClick={() => {
								setGlobalManagement(false);
							}}
						>
							关闭管理权限
						</Button>
					</>
				) : (
					<>
						<Form.Item label="管理密码" name="globalManagementPassword">
							<Input type="password" />
						</Form.Item>

						<Button htmlType="submit">打开管理权限</Button>
					</>
				)}

				<Button
					onClick={() => {
						diagnose();
					}}
				>
					打开远程调试
				</Button>
			</Form>
		</div>
	);
}
